import React, { useState, useEffect } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { NudgeSpotlight as AKNudgeSpotlight } from '@atlassiansox/nudge-tooltip';

import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	useOnboardingState,
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { MENTION_REMINDERS_ONBOARDING_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useChoreographerAssets } from '@confluence/choreographer-services';

import { useMentionRemindersEligible } from '../../hooks/useMentionRemindersEligible';

const i18n = defineMessages({
	mentionRemindersNudgeHeader: {
		id: 'confluence-analytics.mention-reminders.button.nudge.header',
		defaultMessage: 'Remind people to view',
		description: 'A header on a nudge telling the user to remind other users to view the page',
	},
	mentionRemindersNudgeBody: {
		id: 'confluence-analytics.mention-reminders.button.nudge.body',
		defaultMessage: 'Re-send notifications to encourage @mentioned teammates to view the page.',
		description: 'Body text on a nudge explaining why the user should remind other users',
	},
	mentionRemindersDismissButton: {
		id: 'confluence-analytics.mention-reminders.dismiss.button',
		defaultMessage: 'Dismiss',
		description: 'A label on a button to dismiss a nudge',
	},
	mentionRemindersButton: {
		id: 'confluence-analytics.mention-reminders.remind.button',
		defaultMessage: 'Send reminders',
		description: 'A label on a button to send a reminder',
	},
	analyticsBylineNudgeHeader: {
		id: 'confluence-analytics.analytics-byline.nudge.header',
		defaultMessage: 'Page mentions',
		description:
			'A header on a nudge around the analytics byline which provides page insights data',
	},
	analyticsBylineNudgeBody: {
		id: 'confluence-analytics.analytics-byline.nudge.body',
		defaultMessage: 'Check who’s seen your page and how often it’s been viewed.',
		description:
			'Body text on a nudge explaining that the analytics byline shows how many times the page was viewed',
	},
	analyticsBylineDismissButton: {
		id: 'confluence-analytics.analytics-byline.dismiss.button',
		defaultMessage: 'Dismiss',
		description: 'A label on a button to dismiss a nudge',
	},
	analyticsBylineNextButton: {
		id: 'confluence-analytics.analytics-byline.next.button',
		defaultMessage: 'Next',
		description: 'A label on a button to go to the next screen',
	},
});

const nudgeStyles = xcss({
	display: 'inline-block',
	marginRight: 'space.050',
	marginLeft: 'space.050',
});

const childrenWrapperStyles = xcss({
	display: 'inline-block',
});

export enum NudgeLocation {
	ANALYTICS_BYLINE = 'analyticsByline',
	REMINDER_BUTTON = 'reminderButton',
}

interface NudgeProps {
	children: ReactNode;
	nudgeName: string;
	onConfirm?: () => void;
	contentId: string;
}

interface NudgeMap {
	[key: string]: {
		source: string;
		onboardingKey: string;
		onShowEventName: string;
		onClickEventName: string;
		onDismissEventName: string;
	};
}

const nudgeMapping: NudgeMap = {
	[NudgeLocation.ANALYTICS_BYLINE]: {
		source: 'AnalyticsByline',
		onboardingKey: MENTION_REMINDERS_ONBOARDING_STATE_KEYS.ANALYTICS_BYLINE_NUDGE_SEEN,
		onShowEventName: 'mentionsReminderBylineNudge',
		onClickEventName: 'mentionsReminderBylineNudgeClicked',
		onDismissEventName: 'mentionsReminderBylineNudgeDismissed',
	},
	[NudgeLocation.REMINDER_BUTTON]: {
		source: 'AnalyticsDialog',
		onboardingKey: MENTION_REMINDERS_ONBOARDING_STATE_KEYS.MENTION_REMINDERS_BUTTON_NUDGE_SEEN,
		onShowEventName: 'mentionsReminderNudge',
		onClickEventName: 'mentionsReminderNudgeClicked',
		onDismissEventName: 'mentionsReminderNudgeDismissed',
	},
};

export const MentionRemindersNudgeWrapper = ({
	children,
	nudgeName,
	onConfirm,
	contentId,
}: NudgeProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isNudgeShown, setIsNudgeShown] = useState(false);
	const { setOnboardingState } = useOnboardingState();
	const { isMentionRemindersEligible } = useMentionRemindersEligible(contentId);
	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		Object.values(MENTION_REMINDERS_ONBOARDING_STATE_KEYS),
		!isMentionRemindersEligible,
	);

	const { wasAnalyticsBylineNudgeSeen, wasMentionRemindersButtonNudgeSeen } =
		deserializeState(onboardingStateData);

	const onShowNudge = () => {
		void setOnboardingState({
			key: nudgeMapping[nudgeName].onboardingKey,
			value: 'true',
		});

		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: nudgeMapping[nudgeName].onShowEventName,
			},
		}).fire();
	};

	const onDismissHandler = () => {
		setIsNudgeShown(false);

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: nudgeMapping[nudgeName].onDismissEventName,
				source: nudgeMapping[nudgeName].source,
			},
		}).fire();
	};

	const onClickHandler = () => {
		onConfirm?.();
		setIsNudgeShown(false);

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: nudgeMapping[nudgeName].onClickEventName,
				source: nudgeMapping[nudgeName].source,
			},
		}).fire();
	};

	useEffect(() => {
		if (!onboardingStateLoading && !onboardingStateError && isMentionRemindersEligible) {
			if (
				(nudgeName === NudgeLocation.ANALYTICS_BYLINE && !wasAnalyticsBylineNudgeSeen) ||
				(nudgeName === NudgeLocation.REMINDER_BUTTON && !wasMentionRemindersButtonNudgeSeen)
			) {
				setIsNudgeShown(true);
			}
		}
	}, [
		onboardingStateLoading,
		onboardingStateError,
		isMentionRemindersEligible,
		nudgeName,
		wasMentionRemindersButtonNudgeSeen,
		wasAnalyticsBylineNudgeSeen,
	]);

	if (
		onboardingStateLoading ||
		onboardingStateError ||
		!isMentionRemindersEligible ||
		!isNudgeShown
	) {
		return <>{children}</>;
	}

	if (nudgeName === NudgeLocation.ANALYTICS_BYLINE) {
		return (
			<Box xcss={nudgeStyles}>
				<NudgeSpotlight
					nudgeName={nudgeName}
					width={300}
					content={<FormattedMessage {...i18n.analyticsBylineNudgeBody} />}
					heading={
						<Heading as="h2" size="medium" color="color.text.inverse">
							<FormattedMessage {...i18n.analyticsBylineNudgeHeader} />
						</Heading>
					}
					position="bottom-start"
					hideSpotlightCardOnOutsideClick={false}
					concealSpotlightOnReferenceHidden
					hidden={!isNudgeShown}
					onShow={onShowNudge}
					actions={[
						{
							text: formatMessage(i18n.analyticsBylineNextButton),
							onClick: onClickHandler,
						},
						{
							text: formatMessage(i18n.analyticsBylineDismissButton),
							onClick: onDismissHandler,
						},
					]}
					setHidden={() => null}
				>
					<Box xcss={childrenWrapperStyles}>{children}</Box>
				</NudgeSpotlight>
			</Box>
		);
	}

	return (
		<NudgeSpotlight
			nudgeName={nudgeName}
			width={325}
			content={<FormattedMessage {...i18n.mentionRemindersNudgeBody} />}
			heading={
				<Heading as="h2" size="medium" color="color.text.inverse">
					<FormattedMessage {...i18n.mentionRemindersNudgeHeader} />
				</Heading>
			}
			position="right-start"
			hideSpotlightCardOnOutsideClick={false}
			concealSpotlightOnReferenceHidden
			// the analytics byline nudge must be seen before the second nudge
			hidden={!isNudgeShown || !wasAnalyticsBylineNudgeSeen}
			onShow={onShowNudge}
			actions={[
				{
					text: formatMessage(i18n.mentionRemindersButton),
					onClick: onClickHandler,
				},
				{
					text: formatMessage(i18n.mentionRemindersDismissButton),
					onClick: onDismissHandler,
				},
			]}
			setHidden={() => null}
		>
			<div>{children}</div>
		</NudgeSpotlight>
	);
};

// Wrapper component making NudgeSpotlight from platform package integrated with Choreographer API
function NudgeSpotlight({
	nudgeName,
	...passThrough
}: ComponentProps<typeof AKNudgeSpotlight> & {
	nudgeName: NudgeProps['nudgeName'];
}) {
	const { useChoreographedRender } = useChoreographerAssets();

	const canShow = useChoreographedRender({
		messageId: `cc-mention-reminder-nudge-${nudgeName}`,
	});

	return canShow ? <AKNudgeSpotlight {...passThrough} /> : null;
}
