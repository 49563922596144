import gql from 'graphql-tag';

import { UserPermissionFragment } from '@confluence/external-collab-ui/entry-points/UserPermissionFragment';

export const ContentAnalyticsViewersUnifiedQuery = gql`
	query ContentAnalyticsViewersUnifiedQuery($contentId: ID!, $fromDate: String) {
		contentAnalyticsViewers(contentId: $contentId, fromDate: $fromDate) {
			count
		}
		...UserPermissionFragment
	}

	${UserPermissionFragment}
`;
