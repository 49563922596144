import { defineMessages } from 'react-intl-next';

export const GuestRequestToUpgradeByAnalytics = defineMessages({
	flagTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-by-analytics.request-upgrade-title',
		defaultMessage: 'You can’t view analytics content as a guest',
		description:
			'Flag notification title letting the user know they are a guest and cannot view analytics content',
	},
	requestUpgradeFlagDescription: {
		id: 'external-collab-ui.guest-request-to-upgrade-by-analytics.request-upgrade-body',
		defaultMessage: 'To view analytics, you need full access to Confluence.',
		description:
			'Context which explains that upgrading from a guest to a user unlocks more features',
	},
	requestPendingFlagTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-by-analytics.request-pending-title',
		defaultMessage: 'You can’t view analytics as a guest',
		description:
			'Flag notification title while request is pending that informs guest that they cannot access some features as a guest',
	},
	requestPendingFlagDescription: {
		id: 'external-collab-ui.guest-request-to-upgrade-by-analytics.request-pending-body',
		defaultMessage:
			'Your admin must approve your request and grant you full access to Confluence before you can view analytics. ',
		description:
			'Flag notification description while request is pending that informs guest that they cannot access some features as a guest',
	},
	requestDeniedFlagTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-by-analytics.request-denied-title',
		defaultMessage: 'You can’t view analytics as a guest',
		description:
			'Flag notification title informing guest that they cannot access some features as a guest',
	},
});
