import gql from 'graphql-tag';

export const NotifyUsersOnFirstViewMutation = gql`
	mutation NotifyUsersOnFirstViewMutation($input: ID!) {
		notifyUsersOnFirstView(contentId: $input) {
			success
			errors {
				message
			}
		}
	}
`;
