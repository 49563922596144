import { useSessionData } from '@confluence/session-data';
import { expVal } from '@confluence/feature-experiments';

import { isEditionAllowed } from '../utils';

import { useAnalyticsByLine } from './useAnalyticsByLine';
import type { UseAnalyticsByLineHook } from './UseAnalyticsByLineHook';
import { useAnalyticsByLineUpsell } from './useAnalyticsByLineUpsell';

export const useAnalyticsByLineEntryPoint: UseAnalyticsByLineHook = ({ skip, ...args }) => {
	const { edition } = useSessionData();

	// Show the non-upsell experience for paid editions i.e. the actual feature "Page insights"?
	const isNonUpsell = isEditionAllowed(edition) && !skip;

	const nonUpsellPopup = useAnalyticsByLine({ ...args, skip: !isNonUpsell });

	// Fire A/A exposure for new page insights experiment for free editions

	if (!isNonUpsell) {
		expVal('confluence_insight_feature_gate_aa', 'cohort', 'not-enrolled');
	}

	// Show the upsell experience for free editions
	const upsellPopup = useAnalyticsByLineUpsell({ ...args, skip: isNonUpsell });

	return isNonUpsell ? nonUpsellPopup : upsellPopup;
};
