import { useCallback, useState } from 'react';

import { type SSR_PARTIAL_COMPONENT, checkSSRPartialSuccess } from '@confluence/ssr-utilities';

import { SSREvent } from './SSREvent';

export type UseSSROptions = {
	ssrEventCaptureKey: string;
	name: keyof typeof SSR_PARTIAL_COMPONENT;
};

export type UseSSRResult = {
	onSSRCapturedEvent: (event: SSREvent, callback: () => void) => void;
	wasSSREventTriggered: (event: SSREvent) => boolean;
	wasComponentSSRed: boolean;
};

export type TriggeredSSRCapturedEvents = { [key in SSREvent]?: boolean };

export const useSSR = ({ ssrEventCaptureKey, name }: UseSSROptions): UseSSRResult => {
	const [triggeredSSRCapturedEvents, setTriggeredSSRCapturedEvents] =
		useState<TriggeredSSRCapturedEvents>({});

	const wasSSREventTriggered = useCallback(
		(event: SSREvent) => {
			return !!triggeredSSRCapturedEvents[event];
		},
		[triggeredSSRCapturedEvents],
	);

	const updateSSRCapturedEvent = (event: SSREvent) => {
		switch (event) {
			case SSREvent.CLICK:
				return { click: true };
			case SSREvent.FOCUS:
				return { focus: true };
			case SSREvent.HOVER:
				return { hover: true };
		}
	};

	const wasSSREventCaptured = useCallback(
		(event: SSREvent) => {
			return !!window?.__SSR_EVENTS_CAPTURE__?.[ssrEventCaptureKey]?.[event];
		},
		[ssrEventCaptureKey],
	);

	const onSSRCapturedEvent = useCallback(
		(event: SSREvent, callback: () => void) => {
			if (wasSSREventTriggered(event)) {
				return;
			}
			if (wasSSREventCaptured(event)) {
				callback();
				const updatedEvent = updateSSRCapturedEvent(event);
				setTriggeredSSRCapturedEvents((triggeredSSRCapturedEvents) => ({
					...triggeredSSRCapturedEvents,
					...updatedEvent,
				}));
			}
		},
		[wasSSREventCaptured, wasSSREventTriggered],
	);

	return {
		onSSRCapturedEvent,
		wasSSREventTriggered,
		wasComponentSSRed: checkSSRPartialSuccess(name),
	};
};
